import React, { useRef, useState, useEffect } from "react";
import {
  useNavigate,
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import VideoJitsi from "components/VideoCall/VideoJitsi";
import VideoDial from "components/VideoCall/VideoDial";
import VideoRecieve from "components/VideoCall/VideoRecieve";
import { rejectCall } from "api/api";
import { useErrorHandler } from "react-error-boundary";

export default function VideoCall() {
  const navigate = useNavigate();
  const params = useParams();
  const [room_id, setRoomId] = useState("");
  const [is_join, setIsJoin] = useState(false);
  const location = useLocation();
  const handleError = useErrorHandler();
  const roomRef = useRef(room_id);
  let [searchParams, setSearchParams] = useSearchParams();
  roomRef.current = room_id;

  // Construct the calling URL
const callingUrl = "";// `PFAVideoCall/${callerDetail.role}/${callerDetail.caller_id}/${callerDetail.room_id}?d=${encodeURIComponent(JSON.stringify(callerDetail))}`;

const alreadyLogin = localStorage.getItem("bpp_profile");
if (!alreadyLogin) {
  // Store the calling URL in localStorage
  localStorage.setItem("callingUrl", callingUrl);
}

// Retrieve and log the stored URL
console.log("Get Calling URL From the localStorage:", localStorage.getItem("callingUrl"));
  useEffect(() => {
    if (!location.state && !params.room_id) {
      navigate("/MenuScreen");
    }
  }, []);

  const handleJoinParticipant = (payload) => {
    setIsJoin(true);
  };
  const handleAnswerCall = () => {
    setRoomId(params.room_id);
    setIsJoin(true);
  };

  const handleHangupCall = (payload) => {
    setRoomId("");
    setIsJoin(false);
    navigate("/MenuScreen");
    localStorage.removeItem("callingUrl");
  };

  const handleRejectCall = (data) => {
    // data.room_id = roomRef.current;
    data.room_id = roomRef.current || JSON.parse(localStorage.getItem("callerDetail")).room_id;
    data.rejectedBy = "FM";
    rejectCall(data)
      .then((res) => {
        handleHangupCall();
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  return (
    <div className="darkgraybg p-0">
      <div className="mainPage">
        {params.room_id ? (
          <VideoRecieve
            queryParam={{
              ...JSON.parse(decodeURIComponent(searchParams.get("d"))),
              ...params,
            }}
            is_join={is_join}
            handleHangupCall={handleHangupCall}
            handleAnswerCall={handleAnswerCall}
            handleRejectCall={handleRejectCall}
            room_id={room_id}
          />
        ) : (
          <VideoDial
            handleHangupCall={handleHangupCall}
            setRoomId={setRoomId}
            is_join={is_join}
            handleRejectCall={handleRejectCall}
            room_id={room_id}
          />
        )}

        {room_id && (
          <VideoJitsi
            room_id={room_id}
            handleJoinParticipant={handleJoinParticipant}
            is_join={is_join}
            handleHangupCall={handleHangupCall}
            users={location.state}
            params={params}
          />
        )}
      </div>
    </div>
  );
}
